.profile .files {
  --mb-paragraph: 15px;
  --headline-my: 10px;
  --my-2: 10px;
}

.profile .files {
  p {
    color: var(--p-color, var(--color-4));
    font-weight: 400;
    letter-spacing: var(--ls-1);

    &.profile-paragraph--no-space {
      margin-bottom: 0;
    }
  }

  pre {
    white-space: pre-line;
  }

  .profile-headline {
    letter-spacing: var(--ls-1);
  }
}

.profile .files-info {
  margin-bottom: 25px;
}

.profile .files-upload {
  margin-bottom: 15px;

  .error {
    max-width: 610px;
    margin: 0 auto;
    color: var(--ds-error-50);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.24px;
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 112px;
    margin-bottom: var(--space-2);
    border: var(--upload-border, var(--border-5));
    border-radius: var(--upload-radius, var(--radius));
  }

  &__icon {
    width: 40px;
    height: 30px;
    color: transparent;

    path {
      fill: var(--icon-upload-fill, #6c737a);
    }
  }

  &__info {
    margin-top: var(--my-1);
    color: var(--upload-color, var(--color-3));
    font-size: 12px;
    font-weight: 600;
    letter-spacing: var(--ls-1);

    span {
      color: var(--active);
    }
  }

  &__subtitle {
    margin-top: var(--space-2);
    color: var(--upload-color, var(--color-3));
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    letter-spacing: var(--ls-1);
  }
}

.profile .files-group {
  &__entries {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--my-2);
  }

  .file-entry {
    height: 55px;
    padding: 0 20px;
    background-color: var(--entry-bg, var(--bg-1));
    border-radius: var(--entry-radius, var(--radius));

    &.is-uploading {
      background-color: var(--entry-bg-uploading, var(--bg-4));

      .file-entry__name {
        color: var(--entry-uploading-color, var(--color-2));
      }
    }

    &__header {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &__name {
      overflow: hidden;
      display: block;
      width: 100%;
      margin-left: var(--my-1);
      font-size: 12px;
      color: var(--entry-name-color, var(--white));
      font-weight: 600;
      letter-spacing: var(--ls-2);
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__date {
      margin-left: auto;
      font-size: 12px;
      color: var(--entry-date-color, var(--color-2));
      font-weight: 600;
      letter-spacing: var(--ls-1);
      white-space: nowrap;
    }
  }
}
