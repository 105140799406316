.profile {
  select option {
    color: var(--option-color, var(--color-1));
  }

  select:disabled {
    pointer-events: none;
  }

  select:disabled ~ span {
    opacity: 0;
  }

  p.profile-message--promo {
    color: var(--white);
  }

  select {
    appearance: none;
    border: none;

    &.profile-select--no-label {
      padding: 10px 14px;
    }
  }

  input::placeholder {
    opacity: 0;
  }

  .profile-input,
  .profile-select {
    width: 100%;
    height: 55px;
    padding: 24px var(--px-3) 10px;
    background-color: transparent;
    color: var(--input-color, var(--color-1));
    font-size: 14px;
    font-weight: 600;
    font-family: inherit;
    letter-spacing: var(--ls-1);
    outline: none;
    border: none;
    border-radius: var(--radius);
    transition: border 0.25s ease;
  }

  .profile-input {
    &--dropdown {
      cursor: pointer;
    }
  }

  .profile-input ~ label,
  .profile-select ~ label {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    padding: 18px var(--px-3) 10px;
    height: 100%;
    color: var(--label-color);
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    text-transform: capitalize;
  }

  label.is-floated,
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    text-transform: capitalize;
    opacity: 0.85;
    transform: scale(0.85) translateY(-10px) translateX(calc(2px));
  }

  &-input-dropdown-wrapper.has-hint {
    .input-dropdown img.input-dropdown-arrow {
      position: static;
      transform: initial;
      align-self: center;
    }

    .input-dropdown--phone {
      position: static;
      display: flex;
      height: 100%;
    }

    .input-dropdown-content {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 25px;
      margin-left: 15px;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      border-radius: var(--radius);
      background-repeat: no-repeat;
      background-size: 19px;

      .icon-arrow-down {
        position: absolute;
        right: 0;
        top: 0;
        width: 15px;
        height: 15px;
        background-position: center;
        background-size: contain;
        top: 50%;
        right: 15%;
        transform: translate(0, -50%);
        content: '';
        path {
          fill: var(--color-7);
        }
      }
    }
  }
}

.user-phone {
  &__row {
    display: grid;
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 15px;

    @include clear-mb;

    &.has-double {
      position: relative;
      grid-template-columns: 140px 1fr;
      @include breakpoint-max(800px) {
        grid-template-columns: 110px 1fr;
      }
    }
  }
}

.profile-dropdown {
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  overflow: auto;
  position: absolute;
  left: 0;
  bottom: -15px;
  transform: translateY(100%);
  width: 100%;
  background-color: var(--dropdown-bg, #fff);
  color: var(--dropdown-color, #000);
  border-radius: var(--dropdown-radius, var(--radius));
  box-shadow: 0px 2px 12px 3px var(--dropdown-shadow, var(--bg-3));
  transition: 0.3 ease;

  &__entry {
    padding: var(--px-1) var(--py-3);
    cursor: pointer;

    &:hover {
      background-color: var(--dropdown-hover-bg, #e0e0e4);
    }
    &:first-child {
      padding-top: var(--px-2);
    }
    &:last-child {
      padding-bottom: var(--px-2);
    }
  }

  &.is-active {
    opacity: 1;
    pointer-events: initial;
  }
}

.profile-form {
  display: grid;
  grid-gap: var(--form-gap, var(--my-2));

  .btn {
    margin-top: var(--form-btn-m, 5px);
    &.is-disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &--promo,
  &--pass {
    margin-bottom: var(--pass-mb, var(--my-4));
  }

  &--privacy {
    margin-bottom: var(--privacy-mb, var(--my-4));
    grid-gap: var(--my-4);
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__group {
    position: relative;
    flex-grow: 1;
    background-color: var(--input-bg, var(--bg-1));
    font-size: 14px;
    font-weight: 600;
    border-radius: var(--radius);
    border-top: 2px solid transparent;
    
    &-show-password {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-eye,
      .icon-eye-slashed {
        path {
          fill: var(--neutral-60);
        }
      }
      .profile-input {
        padding-right: 0;
      }
      .icon-cp {
        padding-right: var(--space-5);
      }
    }

    &.is-disabled {
      background-color: var(--bg-10);
      label,
      input {
        color: var(--color-10);
      }
    }

    &--select {
      @include breakpoint-min(1200px) {
        display: none;
      }
    }

    &--dropdown {
      display: none;
      @include breakpoint-min(1200px) {
        display: block;
      }
    }

    &--mr {
      margin-right: var(--mx-1);
    }

    &.has-error {
      border-style: solid;
      border-color: var(--error);
      border-width: var(--erorr-border-width, 2px 0 0 0);
      transition: margin 0.2s ease;
    }

    &.is-active {
      .icon-dropdown-arrow {
        transform: rotateX(180deg);
      }
      .profile-dropdown {
        pointer-events: initial;
        opacity: 1;
      }
    }

    &.is-disabled {
      pointer-events: none;

      .profile-input {
        pointer-events: none;
      }

      .icon-dropdown-arrow {
        opacity: 0;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    transition: transform 0.33s linear;

    .icon-cp {
      display: flex;
      width: 16px;
      height: 14px;
      transition: transform 0.3s linear;
    }
  }

  &__error {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    color: var(--error);
    font-size: 10px;
    font-weight: 400;
  }
}

.profile-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 15px;
    border: var(--checkbox-border, var(--border-2));
    border-radius: 5px;

    .icon-cp {
      opacity: 0;
      width: 14px;
      height: 14px;
    }

    .icon-cp path {
      fill: var(--tick-fill, #000);
    }
  }

  &__label {
    color: var(--label-color, var(--color-4));
    font-size: 13px;
    font-weight: 600;
  }

  &.is-active {
    opacity: 1;
  }

  &.is-active .profile-checkbox__input {
    border-color: var(--checkbox-active);
    background-color: var(--checkbox-active);
    .icon-cp {
      opacity: 1;
    }
  }
}

.hint-dropdown {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--input-bg, var(--bg-1));
  border-radius: var(--radius);

  &__overlay {
    display: none;
    z-index: 13;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 55%);
  }

  &__selected {
    padding-left: 20px;
    background-position: 0 50%;
    background-size: 14px;
    background-repeat: no-repeat;
    color: var(--color-6);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.025em;
    @include ellipsis;
  }

  &__content {
    display: none;
    z-index: 14;
    position: absolute;
    top: calc(var(--hint-input-h) + 20px);
    left: 0;
    flex-flow: column;
    width: 100%;
    max-width: 50%;
    padding-left: calc(13px + var(--safe-area-left));
    padding-right: calc(13px + var(--safe-area-right));
    border-radius: 3px;

    @include breakpoint-max(800px) {
      position: fixed;
      top: calc((100vh - 267px) / 2);
      max-width: 100%;
    }

    &.is-active {
      display: flex;

      & + div {
        display: block;
      }
    }
  }

  @include breakpoint-min(800px) {
    .hint-dropdown__content.is-active + div {
      display: none;
    }
  }

  input.hint-dropdown__input {
    width: 100%;
    height: var(--hint-input-h);
    margin-bottom: 0;
    padding: var(--hint-input-px) var(--hint-input-py);
    font-size: 12px;
    font-weight: 700;
    color: var(--input-color, var(--black));
    background: var(--white) url(/img/new-version/icons/grey/search.svg) no-repeat right 15px center/17px;
    border: initial;
    border-bottom: 1px solid #e0e0e0;
    @include radius-top(var(--radius-primary));
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 21%);
    &::placeholder {
      color: var(--black);
      opacity: 1;
    }
  }

  span.hint-dropdown__clear {
    position: absolute;
    top: calc(var(--hint-input-h) / 2);
    right: var(--hint-input-px);
    width: var(--hint-close-size);
    height: var(--hint-close-size);
    transform: translateY(calc(var(--hint-close-size) / -2));

    path {
      fill: var(--black);
    }

    &.is-invisible {
      display: none;
    }
  }

  &-error {
    display: none;
    padding: 9px 8px;
    background-color: #a90101;
    color: var(--white);
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    @include radius-bottom(var(--radius-primary));

    &.is-active {
      display: block;
    }

  }

  &-list {
    overflow: auto;
    display: block;
    height: 100%;
    max-height: 220px;
    flex-grow: 1;
    background-color: var(--bg-6);
    color: var(--black);
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);

    @include breakpoint-max(800px) {
      @include radius-bottom(var(--radius-primary));
    }

    @include breakpoint-min(800px) {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
        margin-bottom: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #cdcdcd;
      }
    }

    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 21%);

    &__entry {
      padding: 9px 10px 9px 35px;
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: left 8px center;
      background-color: #fafafa;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: -0.025em;
      text-transform: initial;
      @include ellipsis;
      &:hover {
        background-color: #ececec;
      }
      cursor: pointer;
    }
  }
}
